<!--  -->
<template>
  <div>
    <pHeader  fixed/>
    <div class="main">
      <div class="main-inner layout-flex">
        <div class="content">
          <div class="content-inner">
              <p class="header-title">{{title}}</p>
              <h1>导言</h1>
              <p v-html="content">
              </p>
            </div>
        </div>
        <div class="aside isPhone">
          <div class="aside-inner">
            <div :class="['category-item',{'category-active':current==index}]" v-for="(item,index) in category" :key="index" @click="totarget('agress-'+index,index)"> 
              <span>{{item}}</span>
              <!-- <a href="">{{item}}</a> -->
            </div>
            <div class="category-index" :style="{top:top+'px'}"></div>
          </div>
        </div>
      </div>
    </div>
    <pFooter />
  </div>
</template>

<script>
import {  getAgreement
} from '@/api/frontApi'
import pHeader from "@/components/pc/p-header/p-header.vue";
import pFooter from "@/components/pc/p-footer/p-footer"
export default {
  data () {
    return {
      title:"",
      content:'',
      step:0,
      top:0,
      current:0,
      category:[]
    };
  },

  components: {
    pHeader,
    pFooter
  },

  computed: {},
created(){
  getAgreement({code:"x001"}).then(res=>{
    this.title=res.title
    this.content= res.content
    this.$nextTick(()=>{
      this.getHeaderTitle()
    })
  })
 
},
mounted(){
  // this.getHeaderTitle()
  window.addEventListener('scroll',this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
},
  methods: {
    getHeaderTitle(){
      //获取所有h1
      this.category=[]
      var allH=document.querySelectorAll('.main h1')
      allH.forEach((h,index)=>{
        console.log(h);
        h.setAttribute('class','category-content')
        h.setAttribute('index',index)
        h.setAttribute('id','agress-'+index)
        this.category.push(h.innerText) 
      })
    },
    handleScroll(e){
      e=e||window.event
      e.preventDefault();  
      e.stopPropagation();
      var agress=document.querySelectorAll('.category-content')
      agress.forEach(item=>{
        if(item.getBoundingClientRect().top<=150&&item.getBoundingClientRect().top>100){
        let num=item.getAttribute('index')
        this.top=num*36
        this.current=num
        }
      })
    },
    totarget(s,i){
      window.removeEventListener('scroll',this.handleScroll,false);
      this.top=i * 36
      this.current=i
      // document.getElementById(s).scrollIntoView()//也可以使用这种方法，但是头部使用了定位
      var total = this.$el.querySelector('#'+s).offsetTop - 125
      var distance = document.documentElement.scrollTop || document.body.scrollTop
      this.step = total/100
      if (total > distance) {
        this.smoothDown(distance,total,this.step)
        setTimeout(()=>{
          window.addEventListener('scroll',this.handleScroll,false);
        },1000)
      } else {
        let newTotal = distance - total
        this.step = newTotal / 100
        this.smoothUp(distance,total,this.step)
        setTimeout(()=>{
          window.addEventListener('scroll',this.handleScroll,false);
        },1000)
      }
    },
     smoothDown (d,t,s) {
          if (d < t) {
            var slideDown=setInterval(()=>{
              d += s
              document.body.scrollTop = d   
              document.documentElement.scrollTop = d
              if(d >= t){
                clearInterval(slideDown)
              }
            },5)
          } else {
            document.body.scrollTop = t   
            document.documentElement.scrollTop = t
          }
        },
       smoothUp (d,t,s) {
          if (d > t) {
             var slideUp=setInterval(()=>{
              d -= s
              document.body.scrollTop = d   
              document.documentElement.scrollTop = d
              if(d <= t){
                clearInterval(slideUp)
              }
            },5)
          } else {           
             document.body.scrollTop = t   
            document.documentElement.scrollTop = t  
          
          }  
      }
  }
}

</script>
<style lang='scss' scoped>
.main{
  padding: 125px 0 100px; 
  .main-inner{
    max-width: 1200px;
    margin:0 auto;
    .content{
     width: 80%;
      padding-right: 50px;
      display: flex;
      justify-content: flex-end;
      .content-inner{
         width: 750px;
         .header-title{
           margin-bottom: 50px;
           font-size: 34px;
           font-weight: bold;
         }
         h1{
           margin-bottom: 30px;
         }
         p{
           line-height: 1.6;
           margin-bottom: 10px;
         }
         
      }
    }
    .aside{
      width:20%;
      .aside-inner{
        width: 100%;
        border-left:2px solid rgba(0,0,0,.05);
        position: fixed;
        top:150px;
        .category-item{
          padding: 0 0 0 20px;
          width: 165px;
          height: 36px;
          line-height: 36px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          a{
            color: rgba(0,0,0,.9);
            font-size: 15px;
          }
        }
        .category-active{
          color: #2468f2;
        }
        .category-index{
          width: 2px;
          height:36px;
          background: #2468f2;
          position: absolute;
          left:-2px;
          transition: top 0.2s;
        }
      }
  }
  }
  }
  @media screen and (max-width:996px){
    .isPhone{
      display: none;
    }
    .main{
      .main-inner{
            max-width: 900px;
             .content{
              width: 100%;
              padding-right: 0;
              justify-content: center;
              .content-inner{
                width: 90%;
              }
          }
          }
         
    }
   
  }
</style>